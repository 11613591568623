.advertisements {
    padding: 20px 0 10px 0;
    background-color: #e8f3ff;
    ul {
        @media #{$PC} {
            width: $main_width;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
        }
        >li {
            margin-bottom: 10px;
            text-align: center;
            @media #{$PC} {
                margin-left: 10px;
                width: 262px;
            }
            a {
                display: block;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(4n+1) {
                @media #{$PC} {
                    margin-left: 0;
                }
            }
        }
    }
}
