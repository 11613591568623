@import '../common/css/setting';

.slider {
    margin-bottom: 15px;
    max-height: 18vh;
    overflow: hidden;
    @media #{$SP} {
        max-height: 120px;
    }
    @media #{$Phablet} {
        max-height: 135px;
    }
    @media #{$Nexus7} {
        max-height: 192px;
    }
    @media #{$Tablet} {
        max-height: 245px;
    }
    @media #{$PC} {
        max-height: none;
        height: 343px;
    }
    &-item {
        @media #{$PC} {
            height: 343px;
            width: $main_width;
            margin: 0 auto;
            padding: 0 10px;
        }
    }
}

.searchCarwash {
    &-gps {
        margin-bottom: 15px;
    }
}

.featureLinks {
    margin-bottom: 5px;
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
            width: 31%;
            height: 70px;
            margin-bottom: 5px;
            box-sizing: border-box;
            background-color: #0c52a3;
            border-bottom: 2px solid #042863;
            >a {
                height: 100%;
                display: block;
                text-align: center;
                font-size: 0.7rem;
                color: #fff;
                p {
                    height: 40px;
                    padding: 7px 0 3px 0;
                    text-align: center;
                }
            }
        }
    }
}

#guideNavi {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
            width: 45%;
            height: 70px;
            margin-bottom: 10px;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            >a {
                height: 100%;
                display: block;
                >p {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    color: #fff;
                    text-align: center;
                    background-color: rgba(0, 0, 0, .5);
                    &:after {
                        top: 1px;
                        right: 0;
                    }
                }
            }
        }
    }
    .body {
        background-image: url(../img/img_top_guide_01.png)
    }
    .front {
        background-image: url(../img/img_top_guide_02.png)
    }
    .wheel {
        background-image: url(../img/img_top_guide_03.png)
    }
    .headlight {
        background-image: url(../img/img_top_guide_04.png)
    }
    .interior {
        background-image: url(../img/img_top_guide_05.png)
    }
    .underbody {
        background-image: url(../img/img_top_guide_06.png)
    }
}

.benefit {
    .desc {
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }
    .register {
        width: 100%;
        margin-bottom: 20px;
        font-size: 0.9rem;
        text-align: center;
        .button {
            width: 80%;
            margin: 0 auto;
            padding: 10px;
            display: block;
            background-color: #0c52a3;
            box-shadow: #042863 0 3px 0 0;
            background-repeat: no-repeat;
            background-position: 10px 10px;
            background-size: 23px auto;
            background-image: url(../img/icon_customer_regist.png);
        }
    }
}

@import "../components/service_tab";
@import "../components/service_search";
@import "../components/news";
@import "../components/footer_banner";
