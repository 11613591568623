.checkList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    &-item {
        width: 47%;
        height: 60px;
        margin-bottom: 5px;
        background-color: #f6f6f6;
        box-shadow: 1px 1px 1px #CCC;
        position: relative;
        &:before {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 5px;
            left: 5px;
            display: inline-block;
            background: #888;
        }
    }
    &-banner {
        width: 47%;
        height: 60px;
        margin-bottom: 5px;
        img {
            max-height: 100%;
            width: auto;
        }
    }
}

.tabAskContent {
    .checkbox_hand_area {
        &:before {
            content: "";
            background: url(/common/img/icon_hand_wash.png) #888 center 11px no-repeat;
            background-size: contain;
            background-size: 31px;
        }
    }
    .checkbox_washerstaff_area {
        &:before {
            content: "";
            background: url(/common/img/icon_car_wash_staff.png) #888 center 13px no-repeat;
            background-size: contain;
            background-size: 44px;
        }
    }
    .checkbox_corting_area {
        &:before {
            content: "";
            background: url(/common/img/icon_coating.png) #888 center 11px no-repeat;
            background-size: 30px;
        }
    }
    .checkbox_room_area {
        &:before {
            content: "";
            background: url(/common/img/icon_indoor_cleaning.png) #888 center 14px no-repeat;
            background-size: 33px;
        }
    }
}

.tabTryContent {
    display: none;
    .checkbox_washer_area {
        &:before {
            content: "";
            background: url(/common/img/icon_self_wash.png) #888 center 12px no-repeat;
            background-size: 35px;
        }
    }
}

.location {
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    &-label {
      line-height: 34px;
    }
    &-select {
      width: 40%;
    }
}

.text {
    &-keyword {
        margin-bottom: 10px;
    	padding-bottom: 10px;
        text-align: center;
        >input {
            width: 80%;
        }
    }
}

.washRefineByContainer {
    margin-bottom: 20px;
    .washRefineBy {
        width: 96%;
        margin: 0 auto;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
}
