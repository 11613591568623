.news {
    >a {
        width: 90%;
        display: block;
    }
    .newsLink {
        margin: 0 auto 10px;
        &:last-child {
            ul {
                border-bottom: 0;
            }
        }
    }
    .linkToList {
        width: 30%;
        text-align: center;
        color: #0c52a3;
        background-color: #fff;
        border-bottom: 2px solid #0c52a3;
        @media #{$PC} {
            width: 100px;
        }
        &:after {
            right: 5px;
        }
    }
    .newsList {
        width: 100%;
        padding: 0 0 10px 0;
        position: relative;
        display: flex;
        justify-content: row;
        box-sizing: border-box;
        border-bottom: 1px solid #0c53a3;
        &:after {
            content: "\f105";
            font-size: 1.3rem;
            display: block;
            font-family: FontAwesome;
            position: absolute;
            top: 22px;
            right: 10px;
            line-height: 28px;
            color: #0c53a3;
        }
        li {
            dt {
                color: #4ca5ff;
            }
            .newsImgThumb {
                width: 30%;
                height: 100%;
                border: 1px solid #333;
            }
            &:last-child {
                width: 70%;
                padding-left: 5px;
            }
        }
    }
}
