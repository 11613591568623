.tabMenuBox {
    margin-bottom: 10px;
    .tabMenu {
        display: flex;
        justify-content: space-around;
        border-bottom: 3px solid #0c52a3;
    	li {
            width: 47%;
            color: #fff;
			text-align: center;
            position: relative;
            font-size: 90%;
            box-sizing: border-box;
            border-radius: 5px 5px 0 0;
            background-color: #85a8d1;
            &:after {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 43px;
                left: 0;
                right: 0;
                margin: auto;
                border: 10px solid transparent;
                border-top: 0;
            }
    	}
        .tabList {
            >label {
                padding: 10px;
                position: relative;
                display: block;
                @media #{$PC} {
                    font-size: $px16;
                }
                &:after {
                    content: "";
                    position: absolute;
                }
            }
            .tabLink {
                padding: 10px;
                color: #fff;
                display: block;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                }
            }
        }
    	.tabList1 {
    		label {
                padding-right: 10px;
                &:after {
                    width: 15px;
                    height: 15px;
        			background: url(/common/img/icon_tab_ask.png) right 0 no-repeat;
                    background-size: cover;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
    		}
    	}
    	.tabList2 {
    		label {
                padding-right: 15px;
                &:after {
                    width: 19px;
                    height: 15px;
                    margin-left: 3px;
        			background: url(/common/img/icon_tab_try.png) right 0 no-repeat;
                    background-size: cover;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
    		}
    	}
        .tabBuilding {
            &:after {
                width: 12px;
                height: 15px;
                margin-left: 3px;
                background: url(/common/img/icon_building.png) right 0 no-repeat;
                background-size: cover;
            }
        }
        .tabBalloon {
            &:after {
                width: 18px;
                height: 18px;
                margin-left: 3px;
                background: url(/common/img/icon_speech_balloon_w.png) right 0 no-repeat;
                background-size: cover;
            }
        }
        .tabProfile {
            &:after {
                width: 17px;
                height: 17px;
                background: url(/common/img/icon_customer_service.png) right 0 no-repeat;
                background-size: cover;
            }
        }
        .tabOpen {
            background-color: #0c52a3;
            &:after {
                border-top: 10px solid #0c52a3;
            }
        }
    }
}

.tabContent {
    display: none;
    margin-bottom: 20px;
}

#tabBtn1:checked {
    ~ #tab1 {
        display: block;
    }
    ~ .tabMenuBox {
        li {
            &:first-child {
                background-color: #0c52a3;
            }
            &:first-child:after {
                border-top: 10px solid #0c52a3;
            }
        }
    }
}

#tabBtn2:checked {
   ~ #tab2 {
       display: block;
   }
   ~ .tabMenuBox {
       li {
           &:last-child {
               background-color: #0c52a3;
           }
           &:last-child:after {
               border-top: 10px solid #0c52a3;
           }
       }
   }
}
